
/* Reset body and html to ensure full width and height */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
   /* overflow: hidden; /* Optional: Prevent scrolling if needed */
  }

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw; /* Full viewport width */
  height: 75vh; /* Full viewport height */
  background-color: var(--background-color); /* Adjust to match the green background */
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* Includes padding in width/height calculations */
}
  
  .hero-text h1 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
  }
  
  .hero-text h1 span {
    display: inline-block;
  }
  
  .hero-text .highlight {
    background-color: #a3ffb5; /* Highlight background */
    color: black;
    padding: 0 5px;
    font-size: 3.5rem;
    font-weight: bold;
  }
  
  .hero-text p {
    font-size: 1rem;
    margin-top: 20px;
    color: #a3d8d4; /* Lighter text for description */
  }
  
  .hero-button button {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #a3ffb5; /* Match button color to the highlight */
    color: black;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .hero-button button:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .hero-button .arrow {
    font-size: 1.2rem;
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
  
  .hero-button button:hover .arrow {
    transform: translateX(5px);
  }
  
  /* Additional Home Content Styling */
  .home-content {
    padding: 20px;
    text-align: center;
  }
  
  .home-content h1 {
    font-size: 2rem;
    margin-top: 40px;
  }
  
  .home-content p {
    font-size: 1rem;
    color: #333;
  }

  #root{
    margin: 0px;
    padding: 0px;
  }
  
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  background: #333;
  color: white;
  padding: 10px;
}

header a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

footer {
  background: #333;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}


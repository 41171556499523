.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem -2rem 1rem 2rem;
    background-color: #013220;
    color: white;
    width: 100%;
  }
  
  .logo {
    font-family: 'Arial Black', sans-serif;
    font-size: 1.5rem;
  }
  
  .nav-menu {
    display: flex;
    gap: 2rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  .nav-link:hover {
    color: #90ee90;
  }
  
  .connect-button {
    background-color: #90ee90;
    color: #013220;
    border: none;
    padding: 0.5rem 1rem;
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 1rem 1rem 1rem;
  }
  
  .connect-button .arrow {
    font-size: 1.2rem;
  }
  
  .connect-button:hover {
    background-color: #76c877;
  }
  
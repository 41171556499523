/* src/constants/colors.css */
:root {
    --primary-color: #003f3c; /* Dark Green */
    --secondary-color: #1e293b; /* Slightly lighter Blue */
    --accent-color: #38bdf8; /* Light Blue */
    --background-color: #003f3c; /* Light Gray */
    --text-color: #1f2937; /* Dark Gray */
    --success-color: #22c55e; /* Green */
    --warning-color: #f59e0b; /* Orange */
    --error-color: #ef4444; /* Red */
  }
  